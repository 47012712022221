import { observable, action, makeObservable, computed } from 'mobx';
import { getFormattedLimeadeDateInLocalTime, getFormattedLimeadeTimeStamp } from '@limeade/ux-utilities';
import { DateTime } from 'luxon';
import { isMobile } from 'react-device-detect';

import { RootStore } from './Root.store';
import { Activity, ActivityId, TeamsActivity, PartialActivity } from '../models/Activity';
import i18n from '../i18n';
import { EventType, NotificationType, PageSize, TrackingType, UserChallengeStatus } from '../utilities/constants';
import { DiscoverCategories } from '../utilities/categories';
import { getIsPrivateServerValue, guidToInt } from '../utilities/utilities';
import { TrackingAction } from '../models/TrackingAction';
import ApiWrapper from '../utilities/apiWrapper';
import Logger, { Events } from '../logger/Logger';
import showToast from '../utilities/showToast';
import { isJoined, isActivityTracked, pluralize } from '../utilities/activityUtilities';
import ActivityDetails from '../models/ActivityDetails';
import { TeamParticipant } from 'src/models/TeamParticipant';
import Config from 'src/Config';
import LightPagination from '../lib/LightPagination/LightPagination';
import ExceptionTypes from 'src/utilities/exceptionTypes';

type CategorySection = {
  totalCount?: number;
  activities?: TeamsActivity[];
  activitiesLoadedMore?: TeamsActivity[];
  isLoading?: boolean;
};

type DiscoverPage = {
  [category in DiscoverCategories]: CategorySection;
};

const initDiscoverPage = (): DiscoverPage => {
  return {
    [DiscoverCategories.Featured]: {},
    [DiscoverCategories.RecommendedByEmployer]: {},
    [DiscoverCategories.TopPicksForYou]: {},
    [DiscoverCategories.Wellbeing]: {},
    [DiscoverCategories.Trending]: {},
    [DiscoverCategories.ManagerEngagement]: {},
    [DiscoverCategories.IndividualEngagement]: {},
    [DiscoverCategories.IndividualInclusion]: {},
  };
};
export default class ActivityStore {
  rootStore: RootStore;
  paginationManager = new Map<string, LightPagination>();
  isFirstLoaded = false;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @observable activities: Map<string, TeamsActivity> = new Map<string, TeamsActivity>();
  @observable discoverPage: DiscoverPage = initDiscoverPage();
  @observable error?: Error | undefined | null = null;
  @observable teamData: any = null;
  @observable leaderBoardData: any = null;
  @observable teamDetails: any = null;

  @action
  setError = (error: Error) => {
    this.error = error;
  };

  /**
   * Sets activities to the store
   * @param activites
   */
  setActivities = (activities: TeamsActivity[]) => {
    for (const activity of activities) {
      this.setActivity(activity);
    }
  };

  @action
  setDiscoverActivitiesByCategory = (category: DiscoverCategories, categorySection: CategorySection) => {
    const prevData = this.discoverPage[category];
    const newData = { ...prevData, ...categorySection };
    this.discoverPage[category] = newData;
  };

  getDiscoverActivitiesByCategory = (category: DiscoverCategories) => {
    return this.rootStore.ActivityStore.discoverPage[category];
  };

  /**
   * Reset activities map
   */
  @action
  resetActivities = () => {
    this.activities = new Map<string, TeamsActivity>();
    this.discoverPage = initDiscoverPage();
    this.resetPagination();
  };

  /**
   * Set activity data in store.
   * @param activity the new activity object
   */
  @action
  setActivity = (activity: TeamsActivity) => {
    const oldActivity = this.activities.get(activity.id);

    this.activities.set(activity.id, Object.assign(oldActivity || {}, activity));
  };

  /**
   * Update the activity detail for a activity in store.
   * @param id Activity Id
   * @param details activity details
   */
  @action
  setActivityDetails = (id: ActivityId, details: ActivityDetails) => {
    this.updatePartialActivity(id, { activityDetails: details });
  };

  /**
   * General function to update some partial properties of activity
   * @param id Activity Id
   * @param parialActivity some partial properties
   */
  @action
  updatePartialActivity = (id: ActivityId, parialActivity: PartialActivity) => {
    const oldActivity = this.activities.get(id) as TeamsActivity;
    if (oldActivity) {
      const updatedActivity: TeamsActivity = {
        ...oldActivity,
        ...parialActivity,
      };

      this.setActivity(updatedActivity);
    }
  };

  /**
   * Set the activity is tracked today or not
   * @param id Activity Id
   * @param isTracked is tracked today or not
   */
  @action
  setActivityTracked = (id: ActivityId, isTracked: boolean) => {
    this.updatePartialActivity(id, { isTracked });
  };

  /**
   * Set isLoading value in Activity
   * @param id activity id
   * @param isLoading new isLoading value
   */
  @action
  setActivityIsLoading = (id: ActivityId, isLoading: boolean) => {
    this.updatePartialActivity(id, { isLoading });
  };

  /**
   * Set isLoading value in Activity
   * @param id activity id
   * @param status new status value
   */
  @action
  setActivityStatus = (id: ActivityId, status: UserChallengeStatus) => {
    const oldActivity = this.activities.get(id) as TeamsActivity;

    if (oldActivity) {
      const activityDetails = {
        activityDetails: {
          ...oldActivity.activityDetails,
          Status: status,
        },
      };

      this.updatePartialActivity(id, activityDetails);
    }
  };

  @action
  setIsDisabledTrackingSection = (id: ActivityId, isDisabledTrackingSection: boolean) => {
    this.updatePartialActivity(id, { isDisabledTrackingSection });
  };

  @action
  setIsExpiringSoon = (id: ActivityId, isExpiringSoon: boolean) => {
    this.updatePartialActivity(id, { isExpiringSoon });
  };

  /**
   * Gets all activities in the store
   * @returns The {@link TeamsActivity} array
   */
  @computed get hasActivities(): boolean {
    return this.activities.size > 0;
  }

  /**
   * Gets all activities in the store
   * @returns The {@link TeamsActivity} array
   */
  @computed get getActivities(): TeamsActivity[] {
    return Array.from(this.activities.values()) as TeamsActivity[];
  }

  /**
   * Gets Home activities from the store.
   * @returns The {@link TeamsActivity} array
   */
  @computed get homeActivities(): TeamsActivity[] {
    return Array.from(this.activities.values()).filter(
      (activity) => !activity.activityDetails || isJoined(activity as TeamsActivity)
    ) as TeamsActivity[];
  }

  /**
   * Gets activity by id from activities store.
   * If activity doesn't exist or missing some fields makes request to the server to get all data.
   * @param id string id of the activity
   * @returns The {@link TeamsActivity} object
   */
  getActivityById = async (id: string): Promise<TeamsActivity> => {
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));

    let activity = this.activities.get(id) as TeamsActivity;
    if (!activity?.description) {
      const updatedActivity = await apiWrapper.getActivity(id);
      this.setActivity(updatedActivity as TeamsActivity);
    }

    if (!activity?.activityDetails) {
      activity = await this.updateActivityDetailedInfo(id);
    }

    return activity;
  };

  sendCompleteActivityNotification = async (
    activityDetailsBeforeTracking: ActivityDetails,
    activityId: string
  ): Promise<void> => {
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    const activityGoal: number = activityDetailsBeforeTracking?.TargetGoal;
    const scoreBeforeTracking: number = activityDetailsBeforeTracking?.Progress[0]?.TeamValue || 0;
    const activityDetailsAfterTracking = await apiWrapper.getActivityDetailedInfo(activityId);
    const scoreAfterTracking: number = activityDetailsAfterTracking?.Progress[0]?.TeamValue;
    const tenantId = this.rootStore.AppAuthStore.teamsUserState.teamsUser!.tenantId;

    if (scoreBeforeTracking < activityGoal && scoreAfterTracking >= activityGoal) {
      let limeadeIds: number[] = [];
      let teamParticipants: TeamParticipant[] = [];

      const teamInfo = await apiWrapper.getMyTeamInfo(activityId);

      if (teamInfo?.TeamId) {
        teamParticipants = await apiWrapper.getTeamParticipants(activityId, teamInfo.TeamId);
        limeadeIds = teamParticipants.map((teamParaticipant: TeamParticipant) => teamParaticipant.LimeadeAccountId);
      }

      await this.rootStore.UserMappingStore.fetchUserMappingsByIds(limeadeIds);

      const aadUserIds: string[] = [];
      for (const teamParticipant of teamParticipants) {
        const mapping = this.rootStore.UserMappingStore.getUserMappingById(teamParticipant.LimeadeAccountId);
        if (mapping?.oid) {
          aadUserIds.push(mapping.oid);
        }
      }

      const notification = {
        receiversIds: aadUserIds,
        notificationType: NotificationType.COMPLETE_ACTIVITY,
        tenantId,
        data: { activityId, teamName: teamInfo?.TeamName },
      };

      await apiWrapper.sendTeamsNotification(notification);
    }
  };

  /**
   * Tracks activity progress
   * @param id string id of the activity
   * @param points points to track
   * @param comment comment to track
   */
  @action
  trackProgress = async (id: string, points: string, comment: string, selectedDate: Date) => {
    const activity = await this.getActivityById(id);
    const amountUnit = activity.trackingStrategy?.amountUnit;

    const trackEvent: TrackingAction = {
      Id: guidToInt(activity.id),
      Type: 4,
      ActionType: 'Tracker',
      PrivacyFlag: activity.activityDetails.PrivacyFlag,
      Details: {
        Id: 0,
        Activity: activity.action,
        Type: activity.trackingType === TrackingType.ADD_ALL_NUMBERS ? EventType.NUMBERIC : EventType.BOOLEAN,
        Unit: amountUnit,
        Date: getFormattedLimeadeDateInLocalTime(selectedDate, 'YYYY-MM-DD', {}, 'en' as any),
        Timestamp: getFormattedLimeadeTimeStamp(new Date(), 'X'),
        Value: activity.trackingType === TrackingType.ADD_ALL_NUMBERS ? points : true,
        Comment: comment,
      },
    };

    try {
      const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
      await apiWrapper.trackActivity(trackEvent);
      this.rootStore.UserProfileStore.updateProfile();

      // ADD_ALL_NUMBERS type has no limit on daily track
      if (activity.trackingType !== TrackingType.ADD_ALL_NUMBERS) {
        activity.isTracked = true;
      }

      if (activity.socialTracking) {
        await this.sendCompleteActivityNotification(activity.activityDetails, activity.id);
      }

      // update allarrays related to tracking activity
      await this.updateActivityFromServer(id);
      await this.loadAllTeamInfo(id);

      Logger.trackEvent(Events.ProgressTracked, trackEvent);

      let amountText = `${points}`;

      if (activity.trackingType === TrackingType.ADD_ALL_NUMBERS) {
        amountText = pluralize(Number.parseInt(points), amountUnit);
      }

      showToast.notify(i18n.t('notifications.trackProgress', { points: amountText }));
    } catch (err: any) {
      showToast.error(i18n.t('notifications.error'));
      throw err;
    }
  };

  @action
  preupdatePrivacy = (id: ActivityId, value: boolean) => {
    const activity = this.activities.get(id);
    const activityDetails = { ...activity!.activityDetails, PrivacyFlag: +value };
    this.updatePartialActivity(id, { activityDetails });
  };

  /**
   * Update activity privace in store and on the server.
   * @param id string id of the activity
   * @param value boolean value of activitiy privacy
   */
  @action
  updatePrivacy = async (id: ActivityId, value: boolean) => {
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));

    try {
      await apiWrapper.updateActivityPrivacy(guidToInt(id), value);

      await this.updateActivityFromServer(id);
    } catch (error) {
      showToast.error(i18n.t('notifications.error'));
      throw error;
    }
  };

  /**
   * Leaves from the activity
   * @param id string id of the activity
   */
  @action
  leaveActivity = async (id: ActivityId): Promise<void> => {
    this.setActivityIsLoading(id, true);

    try {
      const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
      await apiWrapper.leaveActivity(id);
      this.setActivityStatus(id, UserChallengeStatus.None);
      showToast.notify(i18n.t('notifications.leaveActivity'));
    } catch (err: any) {
      showToast.error(i18n.t('notifications.error'));
      throw err;
    }

    this.setActivityIsLoading(id, false);
  };

  /**
   * Moves activity to history and removes it from the activities list.
   * @param id string id of the activity
   */
  @action
  moveActivityToHistory = async (id: ActivityId): Promise<void> => {
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    await apiWrapper.moveToHistory(id);

    this.activities.delete(id);
    showToast.notify(i18n.t('notifications.moveActivityToHistory'));
  };

  /**
   * Join to the activity.
   * @param id string id of the activity
   * @param activityTitle the title of the activity
   * @param isPrivate indicate if activity private
   */
  @action
  joinActivity = async (
    id: ActivityId,
    activityTitle: string = '',
    isPrivate: boolean = false,
    isPrivacy: boolean = false
  ): Promise<void> => {
    this.setActivityIsLoading(id, true); // TODO: rework this logic

    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));

    try {
      await apiWrapper.joinActivity(id, isPrivate);
      const activity = await this.updateActivityFromServer(id);
      if (isPrivacy) {
        const privacyFlagIsCorrect = activity.activityDetails.PrivacyFlag === getIsPrivateServerValue(isPrivate);

        if (!privacyFlagIsCorrect) {
          await apiWrapper.leaveActivity(id);

          this.setActivityStatus(id, UserChallengeStatus.None);
          throw new Error(ExceptionTypes.PRIVACY_FLAG_NOT_SET);
        }
      } else {
        this.setActivityStatus(id, UserChallengeStatus.InProgress);
      }

      Logger.trackEvent(Events.TeamJoined, { activityId: id });
      showToast.notify(i18n.t('notifications.joinActivity', { activityTitle }));

      if (this.rootStore.ActivityFilterStore.filterApplied.length) {
        this.rootStore.ActivityFilterStore.logFilterAppliedWhenDetailsOrJoin(id, 'Join');
      }
    } catch (err: any) {
      showToast.error(i18n.t('notifications.error'));
      throw err;
    }

    this.setActivityIsLoading(id, false);
  };

  /**
   * Join to the team and send notification to teammates.
   * @param activityId string id of the activity
   * @param teamId string id of the team
   * @param teamName string team name
   * @param teamParticipants team participants
   */
  @action
  joinTeam = async (
    activityId: ActivityId,
    teamId: string,
    teamName: string,
    teamParticipants?: TeamParticipant[]
  ): Promise<boolean> => {
    const tenantId = this.rootStore.AppAuthStore.teamsUserState.teamsUser!.tenantId;
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    const result = await apiWrapper.joinTeam(activityId, teamId);
    if (result.Data) {
      showToast.notify(i18n.t('notifications.joinTeam.success', { teamName }));

      this.setActivityStatus(activityId, UserChallengeStatus.InProgress);

      if (!teamParticipants) {
        teamParticipants = await apiWrapper.getTeamParticipants(activityId, teamId.toString());
      }

      const limeadeIds = teamParticipants.map((teamParaticipant) => teamParaticipant.LimeadeAccountId);
      await this.rootStore.UserMappingStore.fetchUserMappingsByIds(limeadeIds);
      const aadUserIds: string[] = [];
      for (const teamParticipant of teamParticipants) {
        const mapping = this.rootStore.UserMappingStore.getUserMappingById(teamParticipant.LimeadeAccountId);
        if (mapping?.oid) {
          aadUserIds.push(mapping.oid);
        }
      }

      await apiWrapper.sendTeamsNotification({
        receiversIds: aadUserIds,
        data: { activityId },
        notificationType: NotificationType.JOIN_TEAM,
        tenantId,
      });
      return true;
    }

    const alreadyJoinedText = this.rootStore.LocalizationStore.getString('Fow_AlreadyJoined', teamName);
    showToast.info(alreadyJoinedText);
    return false;
  };

  //#region Server interaction

  /**
   * Sets My activites from the server.
   * @returns The {@link Activity} array
   */
  updateMyActivitiesFromServer = async (): Promise<Activity[]> => {
    if (this.hasActivities) {
      return this.getActivities;
    }

    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    const activities = await apiWrapper.getMyActivities();

    this.setActivities(activities as TeamsActivity[]);

    for (const activity of activities) {
      // Have to set loading before going to the async action
      this.setActivityIsLoading(activity.id, true);
      this.updateActivityDetailedInfo(activity.id);
    }
    return activities;
  };

  resetPagination = () => {
    this.paginationManager.clear();
    this.isFirstLoaded = false;
  };

  showMoreDiscoverActivitiesByCategory = async (category: DiscoverCategories, pageSize: number) => {
    const ActivityFilterStore = this.rootStore.ActivityFilterStore;
    const CardStore = this.rootStore.CardStore;
    const searchFilter = ActivityFilterStore.input;
    const typeFilter = ActivityFilterStore.selectedTypes;
    const includeMyChoiceFilter = ActivityFilterStore.includeMyChoice;
    let lightPagination = this.paginationManager.get(category);
    try {
      if (!lightPagination) {
        const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
        const pageableApi = async (pageSize: number, pagingToken: string | null) => {
          const result = await apiWrapper.fetchDiscoverActivities(
            category,
            pagingToken ?? '',
            searchFilter,
            typeFilter,
            !includeMyChoiceFilter,
            pageSize
          );
          return result.feed;
        };
        lightPagination = new LightPagination(pageableApi);
        this.paginationManager.set(category, lightPagination);
      }

      CardStore.loaderQueue.enqueue(category);
      this.setDiscoverActivitiesByCategory(category, { isLoading: true });
      await lightPagination.showMore(pageSize);

      const displayedItems: TeamsActivity[] = [];
      lightPagination.displayedItems.forEach((item: TeamsActivity) => {
        if (!this.activities.has(item.id)) {
          this.setActivity(item);
          this.updateActivityDetailedInfo(item.id);
        }
        displayedItems.push(this.activities.get(item.id)!);
      });

      this.setDiscoverActivitiesByCategory(category, {
        activities: displayedItems,
        activitiesLoadedMore: lightPagination.curPageItems,
        totalCount: lightPagination.totalCount,
        isLoading: false,
      });

      CardStore.loaderQueue.dequeue();
      if (CardStore.loaderQueue.size() === 0) {
        this.rootStore.CardStore.setIsFirstLoading(false);
      }
    } catch (error: any) {
      this.rootStore.CardStore.setError(error);
      this.rootStore.CardStore.setIsFirstLoading(false);
      throw error;
    }
  };

  firstLoadDiscoverActivities = () => {
    if (!this.isFirstLoaded) {
      this.isFirstLoaded = true;
      const pageSize = isMobile ? PageSize.MOBILE_FIRST_LOAD : PageSize.DESKTOP_FIRST_LOAD;

      Object.values(DiscoverCategories).forEach((category) => {
        this.showMoreDiscoverActivitiesByCategory(category, pageSize);
      });
    }
  };

  showLessDiscoverActivitiesByCategory = (category: DiscoverCategories, pageSize: number) => {
    const lightPagination = this.paginationManager.get(category);
    if (lightPagination) {
      lightPagination.showLess(pageSize);
      const displayedItems = lightPagination.displayedItems.map((item: TeamsActivity) => this.activities.get(item.id)!);
      this.setDiscoverActivitiesByCategory(category, {
        activities: displayedItems,
        activitiesLoadedMore: lightPagination.curPageItems,
        totalCount: lightPagination.totalCount,
        isLoading: false,
      });
    }
  };

  /**
   * Sets activity data from the server.
   * @param id string id of the activity
   * @returns The {@link TeamsActivity} object
   */
  updateActivityFromServer = async (id: ActivityId): Promise<TeamsActivity> => {
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    this.setActivityIsLoading(id, true);
    const activity = await apiWrapper.getTeamsActivity(id);

    this.setActivity(activity);
    this.setActivityIsLoading(id, false);
    return activity;
  };

  /**
   * Sets activity Detailed Info data from the server.
   * @param ActivityId activity id
   * @returns The {@link TeamsActivity} object
   */
  updateActivityDetailedInfo = async (id: ActivityId): Promise<TeamsActivity> => {
    let activityDetails;
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    const activity = this.activities.get(id) as TeamsActivity;
    this.setActivityIsLoading(id, true);
    try {
      activityDetails = await apiWrapper.getActivityDetailedInfo(id);
    } catch (error: any) {
      this.setError(error);
      throw error;
    }

    const isTracked = isActivityTracked(activityDetails, activity.trackingType, new Date());

    try {
      const isDisabledTrackingSection =
        activity.isRequiredToWatch && activity.richMediaId !== undefined && activity.richMediaId !== null
          ? !(await apiWrapper.getRichMediaInsightUserPlayDetails(id, activity.richMediaId))?.isPlayCompleted
          : false;

      this.setIsDisabledTrackingSection(id, isDisabledTrackingSection);
    } catch (error: any) {
      this.setError(error);
      throw error;
    }

    this.setActivityDetails(id, activityDetails);
    this.setActivityTracked(id, isTracked);

    this.updateExpiringSoonActivity(activity.endDate, id);
    this.setActivityIsLoading(id, false);

    return this.activities.get(id) as TeamsActivity;
  };

  updateExpiringSoonActivity = (endDateString: string, id: ActivityId) => {
    const today = DateTime.now();
    const endDate = DateTime.fromISO(endDateString);
    const endingSoonDate = DateTime.fromISO(endDateString).minus({ days: +Config.expiryDaysBeforeEndDate });
    const isExpiringSoon = endingSoonDate <= today && today.toISODate() <= endDate.toISODate();
    this.setIsExpiringSoon(id, isExpiringSoon);
  };

  @computed
  get isLeaderBoardEmpty(): boolean {
    return !this.teamData?.Data?.Leaderboard?.LeaderboardEntries?.length;
  }

  @computed
  get isTeamFull(): (maxTeamSize: number) => boolean {
    return (maxTeamSize: number) => {
      return this.teamDetails?.ParticipantCount >= maxTeamSize;
    };
  }

  getMyTeamLeaderBoard = async (activityId: string): Promise<any> => {
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    return await apiWrapper.getMyTeamLeaderboard(activityId);
  };

  getTeamsLeaderboard = async (activityId: string): Promise<any> => {
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    return await apiWrapper.getTeamsLeaderboard(activityId);
  };

  getMyTeamInfo = async (activityId: string) => {
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    return await apiWrapper.getMyTeamInfo(activityId);
  };

  @action
  loadAllTeamInfo = async (activityId: string): Promise<any> => {
    const [teamData, leaderBoardData, teamDetails] = await Promise.all([
      this.getMyTeamLeaderBoard(activityId),
      this.getTeamsLeaderboard(activityId),
      this.getMyTeamInfo(activityId),
    ]);

    this.teamData = teamData;
    this.leaderBoardData = leaderBoardData;
    this.teamDetails = teamDetails;
  };

  @action
  resetAllTeamInfo = () => {
    this.teamData = null;
    this.leaderBoardData = null;
    this.teamDetails = null;
  };
  //#endregion
}
